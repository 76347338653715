import { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import Permissions from './Permissions';
import Input from '../../../../components/Input/Input';
import AttachPlanModal from './AttachPlanModal';
import Button from '../../../../components/Button/Button';
import { updateSubscriptionDates } from '../../../../services/api/api';
import './PlanSection.css';

export default function PlanSection({
  children,
  data,
  subscriptionData,
  email,
  attachToPlan,
  orgId,
  organizationId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [updateSubscriptionDateToggle, setUpdateSubscriptionDateToggle] = useState(false);
  const [newStartDate, setNewStartDate] = useState(data.startDate || '');

  const permissionsToCheck = ['PublicApi', 'HideGenericsEmails'];

  permissionsToCheck.forEach((permissionToCheck) => {
    const hasElement = data.permissions.some((obj) => obj.name === permissionToCheck);

    if (hasElement) {
      data.permissions.forEach((obj) => {
        if (obj.name === permissionToCheck) {
          obj.exists = true;
        }
      });
    } else {
      data.permissions.push({ name: permissionToCheck, exists: false });
    }
  });

  const handleDateChange = (event) => {
    setNewStartDate(event.target.value);
  };

  const handleSaveNewDate = async () => {
    const payload = {
      organizationId: organizationId,
      startDate: new Date(newStartDate).getTime(),
    };
    const response = await updateSubscriptionDates(payload);
    console.log('Update Success:', response);
    window.location.reload();

    setUpdateSubscriptionDateToggle(false);
  };

  return (
    <div>
      <h1 className="title">{children}</h1>
      <div className="container">
        <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
          <AttachPlanModal orgId={orgId} setIsOpen={setIsOpen} email={email} />
        </Modal>
        <div className="buttons-container">
          {attachToPlan && (
            <div>
              <Button width="200px" type="button" handleClick={() => setIsOpen(true)}>
                Attach to Plan
              </Button>
            </div>
          )}
          {subscriptionData &&
            subscriptionData.subscriptionId &&
            subscriptionData.subscriptionId === 'NA' && (
              <Button
                handleClick={() => setUpdateSubscriptionDateToggle(true)}
                className={'btn'}
                width={220}
              >
                Update Subscription Date
              </Button>
            )}
        </div>
        {updateSubscriptionDateToggle && (
          <Modal
            open={updateSubscriptionDateToggle}
            handleClose={() => setUpdateSubscriptionDateToggle(false)}
          >
            <div style={{ padding: '0 30px', marginBottom: '20px' }}>
              <label htmlFor="newStartDate" style={{ display: 'block', marginBottom: '10px' }}>
                Select New Start Date:
              </label>
              <input
                type="date"
                id="newStartDate"
                value={newStartDate}
                onChange={handleDateChange}
                style={{ width: '100%', padding: '10px', fontSize: '1rem' }}
              />
            </div>
            <div className="modalButtons">
              <Button
                handleClick={() => setUpdateSubscriptionDateToggle(false)}
                className={'btn'}
                width={80}
              >
                Cancel
              </Button>
              <Button handleClick={handleSaveNewDate} className={'btn primaryBtn'} width={80}>
                Save
              </Button>
            </div>
          </Modal>
        )}
        <div
          className={`flexContainer ${
            subscriptionData
              ? new Date(subscriptionData.endDate) < new Date()
                ? 'expired'
                : ''
              : ''
          }`}
        >
          <Input label={'Name'} value={data.name} disabled />
          <Input label={'Plan ID'} value={data.planId} disabled />
          <Input label={'Contact per Launch'} value={data.contactPerLaunch} disabled />
          <Input
            label={'Created At'}
            value={data.createdAt ? new Date(data.createdAt).toLocaleDateString('en-GB') : ''}
            disabled
          />
          <Input label={'Cumulative'} value={data.isCumulative} disabled />
          <Input label={'Max Admins'} value={data.maxAdmins} disabled />
          <Input label={'Max Lists'} value={data.maxLists} disabled />
          <Input label={'Max Seats'} value={data.maxSeats} disabled />
          <Input label={'Max Tags'} value={data.maxTags} disabled />
          <Input label={'Min Seats'} value={data.minSeats} disabled />
          <Input label={'Number of Months'} value={data.numberOfMonths} disabled />
          <Input label={'Number of Seats'} value={data.numberOfSeats} disabled />
          <Input label={'Number of Sequences'} value={data.numberOfSequences} disabled />
          <Input label={'Number of Workflow'} value={data.numberOfWorkflow} disabled />
          <Input label={'Workflow Launches'} value={data.numberOfWorkflowLaunches} disabled />
          <Input label={'Price per Seat'} value={data.pricePerSeat} disabled />
          <Input label={'Version'} value={data.version} disabled />
        </div>
        {subscriptionData && (
          <div
            className={`flexContainer ${
              new Date(subscriptionData.endDate) < new Date() ? 'expired' : ''
            }`}
          >
            <Input
              label={'Subscription ID'}
              value={subscriptionData && subscriptionData.subscriptionId}
              disabled
            />
            <Input
              label={'Start Date'}
              value={
                subscriptionData.startDate
                  ? new Date(subscriptionData.startDate).toLocaleDateString('en-GB')
                  : '--'
              }
              disabled
            />
            <Input
              label={'Last Renewal'}
              value={
                subscriptionData.lastRenewDate
                  ? new Date(subscriptionData.lastRenewDate).toLocaleDateString('en-GB')
                  : '--'
              }
              disabled
            />
            <Input
              label={'End Date'}
              value={
                subscriptionData.endDate
                  ? new Date(subscriptionData.endDate).toLocaleDateString('en-GB')
                  : '--'
              }
              disabled
            />
            <Input
              label={'Deadline'}
              value={
                subscriptionData.nonRenewalNoticeDeadline
                  ? new Date(subscriptionData.nonRenewalNoticeDeadline).toLocaleDateString('en-GB')
                  : '--'
              }
              disabled
            />
          </div>
        )}
        <h2 className="title2">Permissions</h2>
        <hr />
        {data.permissions.map((p, i) => {
          return <Permissions key={p.name} permissionSet={p} index={i} data={data} />;
        })}
      </div>
    </div>
  );
}
