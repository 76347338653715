import DetailsSection from '../DetailsSection/DetailsSection';
import PlanSection from '../PlanSection/PlanSection';
import SubscriptionSection from '../SubscriptionSection/SubscriptionSection';
import { FullPageLoader } from '../../../../components/Loader';
import Button from '../../../../components/Button/Button';
import ErrorPortal from '../../../../components/ErrorPortal/ErrorPortal';
import {
  UnsubscribeModalContent,
  DeleteMemberModalContent,
  SuspendPlanModalContent,
} from '../ModalContent';
import CancelInvitationModalContent from '../ModalContent/CancelInvitationModalContent';
import HomeButton from '../../../../components/HomeButton';
import useOrganization from './Organization.hook';

export default function Organization() {
  const { states, setters, handlers } = useOrganization();
  const {
    data,
    status,
    error,
    showUnsubscribeModal,
    showSuspendPlanModal,
    showDeleteMemberModal,
    showCancelInvitationModal,
    params,
  } = states;
  const { setShowUnsubscribeModal, setShowSuspendPlanModal } = setters;
  const {
    handleUnsubscribe,
    handleSuspend,
    handleRenewPlan,
    deleteMemberMutation,
    cancelInvitationMutation,
  } = handlers;

  if (status === 'loading') {
    return <FullPageLoader />;
  }

  if (error) {
    return <ErrorPortal title="Organization Details" message={error.message} />;
  }

  return (
    <>
      <div id="Details">
        <UnsubscribeModalContent
          isOpen={showUnsubscribeModal}
          setIsOpen={setShowUnsubscribeModal}
          handleUnsubscribe={handleUnsubscribe}
          organizationId={data.organization.organizationId}
        >
          Are you sure you want to Unsubscribe?
        </UnsubscribeModalContent>
        <DeleteMemberModalContent
          isOpen={showDeleteMemberModal}
          handleDeleteMember={deleteMemberMutation}
        />
        <CancelInvitationModalContent
          isOpen={showCancelInvitationModal}
          handleCancelInvitation={cancelInvitationMutation}
        />
        <SuspendPlanModalContent
          isOpen={showSuspendPlanModal}
          setIsOpen={setShowSuspendPlanModal}
          handleSuspendPlan={() => handleSuspend(data.organization.organizationId, true)}
        >
          Are you sure you want to suspend plan?
        </SuspendPlanModalContent>
        <div className="detailsHeader">
          <HomeButton />
          <div className="detailsHeaderButtons">
            {data.organization.subscription ? (
              <Button
                handleClick={() => setShowUnsubscribeModal(true)}
                className={'btn warningBtn'}
              >
                Unsubscribe Plan
              </Button>
            ) : null}
            {data.organization.subscription ? (
              data.organization.suspended ? (
                <Button handleClick={() => handleSuspend(data.organization.organizationId, false)}>
                  Unsuspend Plan
                </Button>
              ) : (
                <Button
                  handleClick={() => setShowSuspendPlanModal(true)}
                  className={'btn dangerBtn'}
                >
                  Suspend Plan
                </Button>
              )
            ) : null}
            {data.organization.organizationId && (
              <Button
                link={`/OrganizationCreditsUsage/?email=${data.organization.email}&organizationId=${data.organization.organizationId}`}
                className={'btn'}
              >
                Organization Credits History
              </Button>
            )}
            {data.organization.organizationId ? (
              <Button
                link={`/UpdateOrganizationPlan/${data.organization.organizationId}?email=${data.organization.email}`}
              >
                Update Plan
              </Button>
            ) : null}
            {data.organization.organizationId ? (
              <Button handleClick={() => handleRenewPlan(data.organization.organizationId)}>
                Renew Plan
              </Button>
            ) : null}
            <Button
              link={`/AddMember/${data.organization.organizationId}?email=${data.organization.email}`}
            >
              Add Member
            </Button>
          </div>
        </div>
        {data.organization && (
          <DetailsSection
            data={data.organization}
            remainingCreditsTable={data.organization.remainingCredits}
            maxAdmins={data.organization.plan ? data.organization.plan.maxAdmins : 0}
            expired={
              data.organization.subscription &&
              new Date(data.organization.subscription.endDate) < new Date()
                ? true
                : false
            }
          >
            Organization Details
          </DetailsSection>
        )}
        {data.organization &&
          data.organization.plan &&
          data.organization.email &&
          data.organization.organizationId && (
            <PlanSection
              data={data.organization.plan}
              subscriptionData={
                data.organization.subscription ? data.organization.subscription : null
              }
              email={data.organization.email}
              orgId={params}
              attachToPlan
              organizationId={data.organization.organizationId}
            >
              Current Plan
            </PlanSection>
          )}
        {data.organization && data.organization.pending && data.organization.pending.plan && (
          <PlanSection data={data.organization.pending.plan} attachToPlan>
            Pending Plan
          </PlanSection>
        )}
        {data.organization &&
          data.organization.pending &&
          data.organization.pending.subscription && (
            <SubscriptionSection data={data.organization.pending.subscription}>
              Pending Subscription
            </SubscriptionSection>
          )}
      </div>
    </>
  );
}
