import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';

export default function SubscriptionSection({ data, children }) {
  return (
    <div>
      <h1 className="title">{children}</h1>
      {data.subscriptionId !== 'NA' && (
        <Button
          handleClick={() => setUpdateSubscriptionDateToggle(true)}
          className={'btn'}
          width={220}
        >
          Update Subscription Date
        </Button>
      )}
      <div className="container" style={{ marginTop: '1rem' }}>
        <div className={'flexContainer'}>
          <Input label={'Subscription ID'} value={data.subscriptionId} disabled />
          <Input
            label={'Start Date'}
            value={data.startDate ? new Date(data.startDate).toLocaleDateString('en-GB') : '--'}
            disabled
          />
          <Input
            label={'Last Renewal'}
            value={
              data.lastRenewDate ? new Date(data.lastRenewDate).toLocaleDateString('en-GB') : '--'
            }
            disabled
          />
          <Input
            label={'End Date'}
            value={data.endDate ? new Date(data.endDate).toLocaleDateString('en-GB') : '--'}
            disabled
          />
          <Input
            label={'Deadline'}
            value={
              data.nonRenewalNoticeDeadline
                ? new Date(data.nonRenewalNoticeDeadline).toLocaleDateString('en-GB')
                : '--'
            }
            disabled
          />
        </div>
      </div>
    </div>
  );
}
